import {
  getWtTicketDetail,
  myWtTicketSubmit,
  notSubmittedForSignature,
  notSubmittedForSignatureSubmit
} from "@/api/workTicket";
import exForm from "@/views/plugin/workflow/mixins/ex-form";

export default {
  mixins: [exForm],
  data(){
    return {
      ticketDetail:{},
      userText: "",
      formProcess: {
        id: '',
        processId: '',
        data: {},
      },
    }
  },
  methods:{
    // 提交
    headRefer(flag) {
      if (this.businessStatus === "CONFIRMING") {
        this.$confirm('提交后无法编辑，确认提交吗?', {
          confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
          cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
          type: "warning",
        }).then(() => {
          this.headSave('submit')
        })
      } else {
        //说明未保存
        if (!this.dataForm.id) {
          this.$message({
            type: "warning",
            message: "请先保存",
          });
          return
        }
        // 发起流程
        this.startProcess(flag)
      }
    },
    startProcess(flag) {
      getWtTicketDetail(this.dataForm.ticketCode).then(res => {

        this.ticketDetail = res.data.data
        notSubmittedForSignature(this.ticketDetail.ticketCode).then(res => {
          if (res.data.data !== "complete") {
            this.userText = res.data.data.toString() + "没有签署"
          } else {
            this.userText = ""
          }

          this.$confirm(this.userText + " 是否要提交", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.userText !== "") {
                notSubmittedForSignatureSubmit(this.ticketDetail.ticketCode)
              }
              this.selectUser(this.ticketDetail,flag)
            }).catch(error => {
              console.log(error)
          });
        })
      })
    },
    selectUser(row,flag){
      //修改草稿状态为待审批
      let data = {
        id: row.id,
        businessStatus: "APPROVING"
      }
      if (this.dataForm.processInstanceId) {
        myWtTicketSubmit(data).then(res => {
          // 关闭当前页面
          this.$router.$avueRouter.closeTag();
          this.$router.back();
        })
      } else {
        this.formProcess.id = row.id
        this.formProcess.data = {...row}
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.processBusinessName = row.content
        //发起流程
        this.handleStartProcess6(true,flag).then(done => {
          // 更新业务数据流程实例id
          data.processInstanceId = done.data.data
          myWtTicketSubmit(data).then(res => {
            //搜索条件未传
            this.$router.$avueRouter.closeTag();
            this.$router.back();
          })
        })
      }
    },
    // 选择流程用户
    handleUserSelection(userIds){
      this.formProcess.assignee = userIds;
      this.selectUser(this.ticketDetail,true)
    },
  },
}

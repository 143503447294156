import {fieldInfo} from "@/api/base/length"
export default {
  data(){
    return {
      tableOption: {
        linklabel: "cvCode",
        column: [{
          label: "事项编号",
          prop: "cvCode",
          align: "left",
          overHidden: true,
          width: 150
        },
          {
            label: "描述",
            prop: "cvDesc",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "地点",
            prop: "location",
            align: fieldInfo.fullAddress.align,
            minWidth: fieldInfo.fullAddress.length,
            overHidden: true
          },
          {
            label: "报告人",
            prop: "reportUserName",
            align: fieldInfo.name_person.align,
            minWidth: fieldInfo.name_person.length,
            overHidden: true
          },
          {
            label: "报告部门",
            prop: "reportDeptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true
          },
          {
            label: "责任部门",
            prop: "respDeptName",
            align: fieldInfo.name_company.align,
            minWidth: fieldInfo.name_company.length,
            overHidden: true
          },
          {
            label: "状态",
            prop: "hdStatus",
            type: 'select',
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=hd_status`,
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            align: fieldInfo.Words4.align,
            minWidth: fieldInfo.Words4.length,
            overHidden: true
          },
          {
            label: "整改期限",
            prop: "rectifyLimitDate",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true
          },
        ],
      },
      tableData: [],
      tableLoading: true,
      isCycle: '',
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      headBtnOptions: [{
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: 'primary',
      },{
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      } ],
    }
  },
}
